html {
  position: relative;
  min-height: 100%;
}

body {
  // font-size: 14px;
  height:100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 135px;
}

footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 125px;
}

img.width-100 {
  width: 100% !important;
}

.navbar-dark, .navbar-light {
  .navbar-nav {
    .nav-link {
      font-size: 1.1rem;
    }
  }
}

.text-120 {
  font-size: 1.2rem;
}

.text-125 {
  font-size: 1.25rem;
}

.text-130 {
  font-size: 1.3rem;
}

.text-150 {
  font-size: 1.5rem;
}

.text-200 {
  font-size: 2.0rem;
}

@media (max-width: 767px) {
  h1, .h1 { font-size: $h1-font-size / 1.3; }
  h2, .h2 { font-size: $h2-font-size / 1.3; }
  h3, .h3 { font-size: $h3-font-size / 1.3; }
  h4, .h4 { font-size: $h4-font-size / 1.3; }
  h5, .h5 { font-size: $h5-font-size / 1.3; }
  h6, .h6 { font-size: $h6-font-size / 1.3; }
  footer.footer {
    font-size: $font-size-base / 1.5;
  }
}
